<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="
          $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : 'txt-left'
        "
      >
        {{ $t("app.sort") }}
      </h4>
      <div class="col-md-12" style="padding: 0">
        <input
          id="sort"
          class="form-control"
          type="text"
          v-model="row.sort"
          v-on:input="onSortChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  namme: "CardSort",
  props: ["sort"],
  data() {
    return {
      row: {
        sort: this.$props.sort,
      },
    };
  },
  methods: {
    onSortChange() {
      const data = {
        sort: this.row.sort,
      };
      this.$emit("sortChange", data);
    },
  },
};
</script>
